// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-business-page-js": () => import("./../../../src/templates/business-page.js" /* webpackChunkName: "component---src-templates-business-page-js" */),
  "component---src-templates-catalog-page-js": () => import("./../../../src/templates/catalog-page.js" /* webpackChunkName: "component---src-templates-catalog-page-js" */),
  "component---src-templates-house-page-js": () => import("./../../../src/templates/house-page.js" /* webpackChunkName: "component---src-templates-house-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-thank-page-js": () => import("./../../../src/templates/thank-page.js" /* webpackChunkName: "component---src-templates-thank-page-js" */)
}

