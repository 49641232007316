import "./src/styles/reset.css";
import "./src/styles/global.css";
import "./src/styles/tokens.css";
import { useEffect } from "react";
const React = require("react");
import { Widget } from "react-jivosite";

export const wrapRootElement = ({element}) => {
    return <>
        {element}
        <Widget id="F4IHqDjQ5d" />
    </>
}